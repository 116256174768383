import React, { useEffect, useState } from "react";

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios'
import { Container } from '@mui/material';
import { PROFILE } from "../../apiEndpoints";
import ConversationBox from "./ConversationBox";
import MobileConversationBox from "./MobileConversationBox";
import Assistant from "./Assistant";
import Controls from "./Controls";
import MobileControls from "./MobileControl";
import * as theme from '../../theme'
import { ACCOUNT } from '../../apiEndpoints'

// import { ArrowBack } from "@mui/icons-material";
// import { PrimaryActionButton } from "./Custom/Button";

export default function VoiceAssistant({user, setUser, token, removeToken, selectedAssistant, setState}) {
  const [timeData, setTimeData] = useState({
    sst_elapsed_time: null,
    assistant_elapsed_time: null,
    elevenlabs_elapsed_time: null,
    totalTime: null
  })
  const [conversation, setConversation] = useState([])
  const [isTextOnly, setIsTextOnly] = useState(false)
  const [allowTextOutputOnly, setAllowTextOutputOnly] = useState(false)
  const [allowTextInput, setAllowTextInput] = useState(false)
  const handleIsTextOnly = (event) => {
    console.log(isTextOnly)
    axios.post(
      ACCOUNT, 
        {
          "text_output": event.target.checked
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }
    )
    setIsTextOnly(event.target.checked);
  };
  const isMobile = true // useMediaQuery('(max-width:1000px)'); // load latest approach view

  const debug = window.location.host.includes('dev.') || window.location.host.includes('localhost:')

  
  useEffect(() => {
    async function fetchUserMeta() {
      const res = await fetch(PROFILE, {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const d = await res.json()
      console.log(d)

      setIsTextOnly(d?.meta?.text_output || false);
      const audioOutputDisabled  =d?.meta?.subscription?.audio_output_disabled || false
      setAllowTextOutputOnly(audioOutputDisabled)

      if (audioOutputDisabled) {
        // force text only
        setIsTextOnly(true)
      }

      // setAllowTextInput(d?.meta?.subscription?.allow_text_input || false)
      setAllowTextInput(true)
    }
    fetchUserMeta()
  }, [token]);
  

  return (
      <>
      
        <Container
          sx={{
            backgroundColor: theme.BODY_BG,
            display: isMobile?'block': 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            width:'100%',
            my: 3,
          }}
        >
        {
          isMobile?
          <>
            <Box sx={{ mt: 1, mb: 1,}}>
              <Assistant
                img={selectedAssistant.image}
                name={selectedAssistant.name}
                title={selectedAssistant.title}
                description={selectedAssistant.description}
              />
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <MobileConversationBox conversation={conversation}  handleIsTextOnly={handleIsTextOnly} isTextOnly={isTextOnly} allowTextOutputOnly={allowTextOutputOnly}/>
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <MobileControls
                user={user}
                setUser={setUser}
                token={token}
                removeToken={removeToken}
                conversation={conversation}
                setConversation={setConversation}
                selectedAssistant={selectedAssistant}
                setState={setState}
                isTextOnly={isTextOnly} 
                setTimeData={setTimeData}
                allowTextInput={allowTextInput}
              />
            </Box>
            {
            debug ? <Box sx={{mt: 2}}>
                {/* <h4> Time taken</h4> */}
                <p>STT: {timeData.sst_elapsed_time}, Assistant: {timeData.assistant_elapsed_time}, Elevn: {timeData.elevenlabs_elapsed_time}</p>
                <p>Total time by api:  {timeData.totalTime}</p>
              </Box> : ""
            }
            
          </>
          :
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={8} display={'flex'} alignItems={'center'}>
              <ConversationBox conversation={conversation} handleIsTextOnly={handleIsTextOnly} isTextOnly={isTextOnly} allowTextOutputOnly={allowTextOutputOnly}/>
            </Grid>
            <Grid item sx={{mt: 5}} xs={4}>
              <Assistant img={selectedAssistant.image} name={selectedAssistant.name} title={selectedAssistant.title} description={selectedAssistant.description} /> 
              <Controls user={user} setUser={setUser} token={token} removeToken={removeToken} conversation={conversation} setConversation={setConversation} selectedAssistant={selectedAssistant} setState={setState} isTextOnly={isTextOnly} setTimeData={setTimeData} />
              {debug ? <Box sx={{mt: 2}}>
                {/* <h4> Time taken</h4> */}
                <p>STT: {timeData.sst_elapsed_time}, Assistant: {timeData.assistant_elapsed_time}, Elevn: {timeData.elevenlabs_elapsed_time}</p>
                <p>Total time by api:  {timeData.totalTime}</p>
              </Box> : "" 
              }
            </Grid>
          </Grid>
        }
      </Container>
    </>
  )
}