import React, { useEffect, useState } from 'react'

import { Alert, Box, Container, Grid, Typography, FormControlLabel, Switch } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PROFILE } from "../../../apiEndpoints";
import Header from '../../header/Header'
import Footer from '../../footer/Footer'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Title from '../Title'

import { ActionButton, MobileActionButton, SubmitButton } from '../../custom/button'
import * as theme from '../../../theme'
import { TextField } from '../../custom/field'

import { ACCOUNT } from '../../../apiEndpoints'
import axios from 'axios'
import { Alert as CustomAlter } from '../../custom/alert'


export default function Account({user, token, removeToken, removeUser, setLoading, setUser}) {
  const navigate = useNavigate()
  const [apiLoading, setAPILoading] = useState(false)
  const [accountValues, setAccountValues] = useState(user)
  const [isTextOnly, setIsTextOnly] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
  });
  const isMobileBuildOnly = process.env.REACT_APP_MOBILE_BUILD === "true"
  
  const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
  };

  const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
  };

  useEffect(() => {
    async function fetchUserMeta() {
      const res = await fetch(PROFILE, {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const d = await res.json()
      setIsTextOnly(d?.meta?.text_output || false)
    }
    fetchUserMeta()
  }, []);

  const handleAccountSubmit = (event) => {
    event.preventDefault()
    setAPILoading(true)
    console.log(accountValues)

    axios.post(
      ACCOUNT, 
        {
            "first_name": accountValues.first_name,
            "last_name": accountValues.last_name,
            "text_output": isTextOnly
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }
    )
    .then(response => response.data)
    .then(data => {
        const userData = data.data.user_data
        setUser(userData)
        setAPILoading(false)
        handleAlertOpen("Success", "Changes have been saved")
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
    });
  
  }
  

  const handleAccountChange = (event) => {
    setAccountValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }))
  }

  const handleUpdatePlan = () => {
    // if (isMobileBuildOnly && process.env.REACT_APP_PAYMENT_LINK_ROUTE) {
    if (false) {
      window.open(process.env.REACT_APP_PAYMENT_LINK_ROUTE, '_blank')
    } else {
      navigate("/payment")
    }
  }
  
  const tokenBalance = parseInt(user.token_balance, 10)
  const isTokenBalanceGreaterThanOne = tokenBalance > 1;

  return (
    <>
    <CustomAlter alert={alert} handleAlertClose={handleAlertClose} />
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header user={user} type='assistant' removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, pb: 5}} >
        <Title text={"Your Account"} buttons={[<ActionButton onClick={() => { navigate("/assistant") }} cursor="pointer" buttonText={"Back to Assistant"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => { navigate("/assistant") }} cursor="pointer" buttonText={"Back"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} />

        
        {/* Account details */}
        <Container component="main"  sx={{
                display: "flex",
                minHeight: '60vh',
                }}  id='signup' maxWidth="xs" >
        
        <Box display={'inline-flex'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '50vh'}}> 
        
          <Box component="form"  onSubmit={handleAccountSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
            
            <Grid container  spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="first-name"
                  name="first_name"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={accountValues.first_name}
                  onChange={handleAccountChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="last_name"
                  autoComplete="last-name"
                  value={accountValues.last_name}
                  onChange={handleAccountChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  readOnly
                  autoComplete="email"
                  value={accountValues.email}
                />
              </Grid>
              <Grid item xs={12}>
                
              <Grid container spacing={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Grid item={11}>
                  <TextField
                    fullWidth
                    id="subscription_plan"
                    label="Usage Plan"
                    name="subscription_plan"
                    readOnly
                    autoComplete="subscription_plan"
                    value={(!accountValues.subscription_plan || accountValues.subscription_plan==="null") ? "Free Version":accountValues.subscription_plan}
                  />
                </Grid>
                {!isMobileBuildOnly && <Grid item={2}>
                  <ActionButton
                    buttonText={"Update Plan"}
                    onClick={handleUpdatePlan}
                  />
                </Grid>}
                {isMobileBuildOnly && <Grid item={2}>
                <Typography paragraph='true'>
                  To manage your plan, please visit our website
                </Typography>
                </Grid> }
              </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="token_used"
                  label="Token Used"
                  name="token_used"
                  autoComplete="token_used"
                  value={accountValues.token_used}
                  readOnly
                />
              </Grid>
              
              {
                !isTokenBalanceGreaterThanOne &&
                <Grid item xs={12}>
                  <Alert sx={{ backgroundColor:theme.WARNING_BG, color:theme.WARNING_FONT }} severity="warning">
                  {
                    tokenBalance === 0?
                    "Sorry! You dont have any tokens":
                    "You have only one token left!"
                  }
                  </Alert>
                </Grid>
              }
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="token_balance"
                  label="Token Balance"
                  name="token_balance"
                  autoComplete="token_balance"
                  readOnly
                  value={accountValues.token_balance}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel control={
                <Switch 
                  id="text_output"
                  name="text_output"
                  checked={isTextOnly}
                  onChange={() => {setIsTextOnly(!isTextOnly)}}
                  style={{
                    color:'#ffffff',
                    '&.Mui-checked': {
                    color: '#ffffff',
                    },
                  }}/>
              } label="Only text output" />
              
            </Grid> */}
            
            <SubmitButton
              loading={apiLoading}
              variant="contained"
              buttonText={"Save Changes"}
              sx={{my:2}}
              
            />
            
          </Box>

        </Box>
        </Container>
      </Box>  
      <Footer disclaimer />
    </div>
    </>
  )
}
