import { Typography, Box } from '@mui/material'

import Assistant from '../custom/assistant/Assistant'

export default function HomeBody() {
  const heading = "AIAssistantExpert.com - Powered By Artificial Intelligence"
  const description = "We provide trained AI Assistants in many professions at your fingertips. Each one is trained to help you with specific questions in a realtime conversation."
  
  return (
    <Box>
      {/* Desktop version */}
      <Box sx={{ display: { xs: 'none', md: 'block',  }}}>
        <Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Typography style={{ fontSize: 30, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black', fontWeight: 'bold'  }} variant='h4'>
                {heading}
              </Typography>     
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{py:1}} >
              <Typography
                variant='h5'
                width={'100vh'}
                sx={{ fontSize: 20, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black' }}
              >
                {description}
              </Typography>
            </Box>
          </Box>

          <Box sx={{mt: 2}} display={'flex'} justifyContent={'center'}>
            <Assistant img={'sara.png'} title={'Marketing Sara'} />
            <Assistant img={'sales.jpg'} title={'Sales Rep. Tim'} />
            {/* <Assistant img={'julie.png'} title={'Accounting Julie'} /> */}
            <Assistant img={'accountant.jpg'} title={'Accounting Margaret'} />
            <Assistant img={'math_teacher.jpg'} title={'Math Tutor Taylor'} />
          </Box>
          <Box sx={{mt: 2}} display={'flex'} justifyContent={'center'}>
            <Assistant img={'david.jpg'} title={'Legal Aid David'} />
            <Assistant img={'cindy.png'} title={'Brand Manager Cindy'} />
            <Assistant img={'tech.jpg'} title={'Tech Support'} />
            <Assistant img={'plumber.jpg'} title={'Plumbing Assistant John'} />
          </Box>
        </Box>
      
      {/* Mobile Version */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Typography style={{ fontSize: 25, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black', fontWeight: 'bold'  }} variant='h4'>
                {heading}
              </Typography>     
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{py:1}} >
              <Typography
                variant='h5'
                width={'100vh'}
                sx={{ fontSize: 20, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black' }}
              >
                {description}
              </Typography>
            </Box>
          </Box>

          <Box sx={{mt: 2}}  justifyContent={'center'} display={'grid'}>
            <Assistant img={'sara.png'} title={'Marketing Sara'} />
            <Assistant img={'sales.jpg'} title={'Sales Rep. Tim'} />
            <Assistant img={'accountant.jpg'} title={'Accounting Margaret'} />
            <Assistant img={'math_teacher.jpg'} title={'Math Tutor Taylor'} />
            <Assistant img={'david.jpg'} title={'Legal Aid David'} />
            <Assistant img={'cindy.png'} title={'Brand Manager Cindy'} />
            <Assistant img={'tech.jpg'} title={'Tech Support'} />
            <Assistant img={'plumber.jpg'} title={'Plumbing Assistant John'} />
          </Box>
      </Box>
    </Box>
  ) 
}
