import { Box, Button } from '@mui/material'
import React from 'react'

export default function Assistant({img, title}) {
  return (
    <Box sx={{margin: 2, width: '155px', height: '160px'}}>
      <img alt={img} style={{objectFit: 'cover'}} width={'100%'} height={'80%'} src={require(`../../../assets/img/assistants/${img}`)} />
      <hr/>
    <Button edge="start" color="inherit" style={{fontSize: 12}}>
      {title}
    </Button>
    </Box>
  )
}
