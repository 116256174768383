import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as theme from '../../../theme'
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
        background: {
            paper: '#ffffff',
          },
        text: {
            primary: '#000000', // Set text to black for visibility
          },
      },
      
      
      components: {
        MuiAutocomplete: {
          styleOverrides: {
            input: {
              color: theme.BODY_FONT,
            },
            clearIndicator: {
              color: theme.BODY_FONT,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': theme.TEXT_FIELD_BORDER,
              '--TextField-brandBorderHoverColor': theme.TEXT_FIELD_HOVER,
              '--TextField-brandBorderFocusedColor': theme.TEXT_FIELD_FOCUS,
              '& label.Mui-focused': {
                color: 'var(--TextField-brandBorderFocusedColor)',
              },
             
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: 'var(--TextField-brandBorderColor)',
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: 'var(--TextField-brandBorderHoverColor)',
              
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: 'var(--TextField-brandBorderFocusedColor)', 
              },
              
            },
          },
        },
        MuiInputLabel: {
            styleOverrides: {
              root: {
                color: 'var(--TextField-brandBorderFocusedColor)', // Set form label text color to white
              },
            },
        },
        MuiChip: {
            styleOverrides: {
              root: {
                backgroundColor: '#ffffff', // Selected chip background white
                color: '#000000', // Chip text color black
                border: '1px solid #000000', // Optional: Add border for visibility
              },
              deleteIcon: {
                color: '#000000', // Cross icon color black
              },
            },
        },
       
      },
});

const MultiSelectBusinesses = ({ businesses, selected,  onSelectedChange }) => {
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const outerTheme = useTheme();
  const handleSelectionChange = (event, value) => {
    setSelectedBusinesses(value);
    onSelectedChange(value); // Pass selected Businesses back to parent
  };

  const selectedValues = React.useMemo(
    () => selected || [],
    [selected],
  );

  useEffect(() => {
    console.log(selected)
    console.log(businesses)
    setSelectedBusinesses(selectedValues)
  }, [businesses, selected, selectedValues])

  

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
        <Autocomplete
      multiple
      options={businesses}
      getOptionLabel={(option) => option.label}
      value={selectedBusinesses}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Businesses"
          placeholder="Type to search..."
        />
      )}
    />
    </ThemeProvider>
    
  );
};

export default MultiSelectBusinesses;
